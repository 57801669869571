/**
 this is a very specific and perhaps slightly hacky way to get the current question name that the user is on
 * The reason we need to extract this is because our name field as it exists in routes is actually a compound field from airtable. It joins a bunch of nested entries and spits out a 3 part name
 * it looks like {questionname}~{questioncontrol}~{typeofScreen}, see gatsby-node for more info
 *
 * * */

import { Route } from '@slices/routeSlice';

const getQuestionNameFromCurrentRoute = (currentRouteObject: Route): string => {
  // strings are demarcated by a tilde ~
  const validName = currentRouteObject?.name;
  if (!validName) {
    // lets just infer and use the "typeOfScreen" as our name (if for some reason its missing, though unlikely)
    return currentRouteObject?.typeOfScreen;
  }
  if (validName.includes('~')) {
    return validName.split('~')[0];
  }
  return validName;
};

export default getQuestionNameFromCurrentRoute;
