import React, { ReactElement, useRef } from 'react';
import { Modal, Flex, IconButton, Label, Divider } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import useTranslation from '@hooks/useTranslation';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import { addNewNotification } from '@slices/gameStateSlice';
import gameConfig from '@content/gameconfig';
import getQuestionNameFromCurrentRoute from '@utils/getQuestionNameFromCurrentRoute';
import { openStandardShare, isBrowser } from '@utils/shareTools';
import { logItem } from '@slices/loggingSlice';

const POTENTIAL_MODAL_WIDTH = 285;
interface ShareModalButtonProps {
  modalTitle: string;
  buttonProps?: any;
  CustomButton?: ReactElement;
  showModalInCenter?: boolean;
  /** will default to just the window origin url */
  textToShare?: string;
  loggingTarget: string;
}

const ShareIconButton = styled(IconButton).attrs({
  showOutline: false,
  variant: 'largeIcon',
  styledImageProps: {
    overflow: 'visible',
    width: '32px',
  },
})({
  overflow: 'visible',
});

const LabelWrapper = styled(Label).attrs({ variant: 'l3' })({
  cursor: 'pointer',
});

const copyTextToClipboard = async (
  text = '',
  copiedNotification = 'Link copied',
  dispatch,
  showVerboseNotification,
) => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      // TODO: localize via airtable
      if (showVerboseNotification) {
        dispatch(addNewNotification(`${copiedNotification}: ${text}`));
      } else {
        dispatch(addNewNotification(copiedNotification));
      }
      return;
    } catch (e) {
      console.error(e);
      dispatch(addNewNotification(e));
    }
  }
};

const ShareModalButton = (props: ShareModalButtonProps) => {
  if (gameConfig.Enable_Sharing === false) {
    return null;
  }
  const buttonPosRef = useRef();
  const dispatch = useAppDispatch();
  const { currentRoute } = useAppSelector(state => state.route);
  const buttonPos = buttonPosRef?.current?.getBoundingClientRect();
  // its the position of the button, and some fuzzy value of how large the container is supposed to be
  // the button's position cannot be less than the guesstimated width of our modal, we add 16px to account for page padding
  const buttonXPos =
    buttonPos?.x < POTENTIAL_MODAL_WIDTH
      ? POTENTIAL_MODAL_WIDTH + 16
      : buttonPos?.x;
  // check if button would be in viewport after subtracting general modal width from it
  // the offset applies FROM CENTER, so negative values go left and vv
  const trayXOffset = buttonXPos - POTENTIAL_MODAL_WIDTH;
  const { t } = useTranslation();
  const {
    loggingTarget = `share_modal_button`,
    buttonProps,
    modalTitle,
    CustomButton = IconButton,
    showModalInCenter = false,
    textToShare = isBrowser ? window?.location.origin : '',
    showVerboseNotification = false,
  } = props;
  const overlayState = useOverlayTriggerState({});

  // https://github.com/bradvin/social-share-urls#twitter
  const twitterShareClick = () => {
    openStandardShare({
      url: `https://twitter.com/intent/tweet?url=${isBrowser && textToShare}`,
      popupWindowHeight: 550,
      popupWindowWidth: 300,
    });

    overlayState.close();
  };

  // https://github.com/bradvin/social-share-urls#facebook
  const facebookShareClick = () => {
    // https://stackoverflow.com/questions/5023602/facebook-share-link-can-you-customize-the-message-body-text
    // tldr: can't share text anymore via this method, only the link
    // TODO: don't do this and use meta tags to replay any text
    openStandardShare({
      url: `https://www.facebook.com/sharer.php?u=${
        isBrowser && window?.location.origin
      }`,
      popupWindowHeight: 626,
      popupWindowWidth: 436,
    });
    overlayState.close();
  };

  return (
    <>
      <Flex alignSelf="center" ref={buttonPosRef}>
        <CustomButton
          // TODO: usePress is buggy, investigate why
          onClick={() => {
            dispatch(
              logItem({
                collection_name: 'events',
                event_type: 'click',
                location: getQuestionNameFromCurrentRoute(currentRoute),
                target: loggingTarget,
              }),
            );
            overlayState.open();
          }}
          iconName="share"
          data-cy="shareButton"
          {...buttonProps}
        />
      </Flex>
      <Modal
        title={modalTitle}
        shouldAutoFocus={false}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose
        closeLabel={t('Close')}
        modalBodyStyles={
          showModalInCenter
            ? {}
            : {
                top: buttonPos?.y,
                left: ['unset', trayXOffset, trayXOffset],
              }
        }
      >
        <Flex mt={5} flexDirection="column">
          <LabelWrapper>
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {t('Facebook')}
              <ShareIconButton
                onPress={() => {
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'click',
                      target: 'facebook_share_button',
                    }),
                  );
                  facebookShareClick();
                }}
                iconName="fb"
              />
            </Flex>
          </LabelWrapper>
          <Divider my={1} />
          <LabelWrapper>
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {t('Twitter')}
              <ShareIconButton
                onPress={() => {
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'click',
                      target: 'twitter_share_button',
                    }),
                  );
                  twitterShareClick();
                }}
                type="submit"
                iconName="twitter"
              />
            </Flex>
          </LabelWrapper>
          <Divider my={1} />
          <LabelWrapper>
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {t('Copy')}
              <IconButton
                onPress={() => {
                  dispatch(
                    logItem({
                      collection_name: 'events',
                      event_type: 'click',
                      target: 'copy_share_button',
                    }),
                  );
                  copyTextToClipboard(
                    textToShare,
                    t('Copied'),
                    dispatch,
                    showVerboseNotification,
                  );
                  // overlayState.close();
                }}
                showOutline={false}
                iconName="link"
                variant="largeIcon"
                sx={{
                  // TODO: theme this
                  backgroundColor: '#949494',
                  borderRadius: '50%',
                }}
                styledImageProps={{
                  color: 'surface',
                  // this only works on non-svg children
                  height: '16px !important',
                }}
              />
            </Flex>
          </LabelWrapper>
        </Flex>
      </Modal>
    </>
  );
};

export default ShareModalButton;
