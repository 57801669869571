import React from 'react';
import { Flex, Box, Label } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppSelector } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import LanguageModalButton from './LanguageModalButton';
import OptionsModalButton from './OptionsModalButton';

const Wrapper = styled(Box).attrs({ as: 'footer' })(p =>
  css({
    isolation: 'isolate',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    pl: [1, 4],
    mb: p.showLanguageToggle ? [-1, 0] : 3,
  }),
);

const PairShareStatus = styled(Label)(() =>
  css({
    textAlign: 'center',
    backgroundColor: '#101010a0',
    color: '#f0f0f0',
    padding: '5px',
    marginTop: '15px',
    marginBottom: '5px',
    borderRadius: '20px',
    transition: 'ease-in-out',
    fontWeight: '400',
  }),
);

const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  const { isPairShareEnabled } = useAppSelector(s => s.gameState);
  const Show_Language_Toggle = true; // } = gameConfig;
  return (
    <Wrapper showLanguageToggle={Show_Language_Toggle}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="row" flex={1}>
          <OptionsModalButton />
        </Flex>
        {isPairShareEnabled && (
          <Flex flex={2} justifyContent="center">
            <PairShareStatus>
              {t('Pair share active')}
            </PairShareStatus>
          </Flex>
        )}
        {Show_Language_Toggle && (
          <Flex flex={1} justifyContent="flex-end">
            <LanguageModalButton />
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};

export default Footer;
