// TODO: type safe because return types are variable
interface GatbsyImage {}

/** access the meat of our symbol data */
const standardSymbolDigger = s =>
  s?.nodes?.[0]?.data?.Content?.[0]?.data?.Symbol?.localFiles[0];

const getSymbol = (
  symbolNode: any,
  getPublicUrl?: boolean,
): {
  type: 'gatsbyImage' | 'staticImage' | 'svg' | 'unknown';
  data: object | string | null;
} => {
  const s = standardSymbolDigger(symbolNode);
  // just return us the zeroth nested match of whatever you passed in..we return whatever is defined and available in data

  if (s?.publicURL && getPublicUrl) {
    // our access path here is a little different
    return { data: s?.publicURL, type: 'staticImage' };
  }

  if (s?.childSvg?.content) {
    return {
      data: s.childSvg.content,
      type: 'svg',
    };
  }
  if (s?.childImageSharp?.gatsbyImageData) {
    return { data: s.childImageSharp.gatsbyImageData, type: 'gatsbyImage' };
  }

  return {
    type: 'unknown',
    data: null,
  };
};

export default getSymbol;
