import checkFeatureFlag from '@utils/checkFeatureFlag';
import *  as loggingClientV1 from './loggingClient-v1';
import * as loggingClientV2 from './loggingClient-v2';
import { LogData as LogDataV1, CommonLogData as CommonLogDataV1 } from './loggingClient-v1';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';
export type LogData = LogDataV1;
export type CommonLogData = CommonLogDataV1;
export type OptionalSessionData = loggingClientV1.OptionalSessionData;
export type SessionData = loggingClientV1.SessionData;
export type SessionInitResponse = loggingClientV1.SessionInitResponse;

const loggingClient = isModulesEnabled ? loggingClientV2 : loggingClientV1;
export { loggingClient };

export const {
  initSession,
  log,
  batchLog,
  logEventNavTiming,
  setLanguage,
  setStreamName
} = isModulesEnabled ? loggingClientV2 : loggingClientV1;
