import { z } from 'zod';

// Use an explicit schema here to make the metrics very clear.
const navPerformanceTimerSchema = z.object({
  connectEnd: z.number(),
  connectStart: z.number(),
  domComplete: z.number(),
  domContentLoadedEventEnd: z.number(),
  domContentLoadedEventStart: z.number(),
  domInteractive: z.number(),
  domainLookupEnd: z.number(),
  domainLookupStart: z.number(),
  fetchStart: z.number(),
  loadEventEnd: z.number(),
  loadEventStart: z.number(),
  redirectEnd: z.number(),
  redirectStart: z.number(),
  requestStart: z.number(),
  responseEnd: z.number(),
  responseStart: z.number(),
  secureConnectionStart: z.number(),
  unloadEventEnd: z.number(),
  unloadEventStart: z.number(),
});

export const navTimersSchema = navPerformanceTimerSchema.extend({
  timeOrigin: z.number(),
});

export type NavTimers = z.infer<typeof navTimersSchema>;

const state = {
  isLoaded: false,
  isInitialized: false,
};

// Call this function early in order to ensure the load event is caught.
export function init(): void {
  state.isInitialized = true;
  if (state.isLoaded === false) {
    window.addEventListener('load', () => {
      state.isLoaded = true;
    });
  }
}

async function waitForPageLoad(): Promise<void> {
  return new Promise(resolve => {
    if (state.isLoaded === false) {
      window.addEventListener('load', () => {
        state.isLoaded = true;
        // Some of the timers aren't set until next event loop.
        setTimeout(() => resolve(), 0);
      });
    } else {
      resolve();
    }
  });
}

// Will return the current window's navigation timers possibly waiting for the
// load event to fire before resolving the promise.
export async function getCurrentTimers(): Promise<NavTimers> {
  if (state.isInitialized === false) {
    throw new Error('Must call init() first');
  }
  const timingEntries = window.performance.getEntriesByType('navigation');
  if (timingEntries == null || timingEntries.length === 0) {
    throw new Error('Error locating window navigation performance metrics.');
  }
  await waitForPageLoad();
  const navTiming = timingEntries[0] as PerformanceNavigationTiming;

  const navTimersParseResult = navPerformanceTimerSchema.safeParse(navTiming);
  if (navTimersParseResult.success === false) {
    throw new Error(
      `Error parsing out navigation performance metrics: ${navTimersParseResult.error.message}`,
    );
  }

  const result = {
    timeOrigin: window.performance.timeOrigin,
    ...navTimersParseResult.data,
  };

  return result;
}
