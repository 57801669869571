import { addNewNotification } from '@slices/gameStateSlice';

interface CopyProps {
  text: string;
  copiedNotification: string;
  dispatch: (e: any) => void;
  showVerboseNotification: boolean;
}

const copyTextToClipboard = async ({
  text = '',
  copiedNotification = 'Link copied',
  dispatch,
  showVerboseNotification,
}: CopyProps) => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      if (showVerboseNotification) {
        dispatch(addNewNotification(`${copiedNotification}: ${text}`));
      } else {
        dispatch(addNewNotification(copiedNotification));
      }
      return;
    } catch (e) {
      console.error(e);
      dispatch(addNewNotification(e));
    }
  }
};

export default copyTextToClipboard;
