import styled from 'styled-components';
import Box from './Box';

const Divider = styled(Box)({
  opacity: 0.2,
  backgroundColor: 'black',
  width: '100%',
  height: '1px',
});

export default Divider;
