import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultSystemTheme from './theme';

interface Props {
  children: ReactNode;
  // TODO: make this more specific
  customColors: Record<string, unknown>;
  funFont: string;
  bodyFont: string;
}

// TODO: the merging of default user theme with custom user theme that comes from airtable can be done in gatsby node itself
// TODO: Can change later to fit different themes
const CustomThemeProvider: FC<Props> = ({
  children,
  customColors,
  funFont,
  bodyFont,
}) => {
  const appliedTheme = {
    ...defaultSystemTheme,
    colors: customColors || defaultSystemTheme.colors,
    fonts: {
      fun: funFont ?? defaultSystemTheme.fonts.fun,
      body: bodyFont ?? defaultSystemTheme.fonts.body,
    },
  };
  return <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
