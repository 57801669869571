import * as Mock from './mock';
import * as Window from './window';

export function init(): void {
    if (process.env.NAME === 'local') {
        return;
    }
    Window.init();
}

export async function getCurrentTimers(): Promise<Window.NavTimers> {
    if (process.env.NAME === 'local') {
        return Mock.defaultTimers;
    }
    return Window.getCurrentTimers();
}

export {Mock, Window};

