import { FeatureFlagList } from 'types';
import gameConfig from '@content/gameconfig';

const multipleExist = (arr: FeatureFlagList[], values: FeatureFlagList[]) =>
  values.every(value => arr.includes(value));

const flagLogicCheck = (flagList: FeatureFlagList[]) => {
  if (
    multipleExist(flagList, ['ANSWER_SPLASH_AFTER_CORRECT', 'NO_ANSWER_SPLASH'])
  ) {
    throw new Error(
      'FLAG_CONFLICTS: Cannot show an answer splash after correct and also hide all answer splashes',
    );
  }
};

type statusTypes = 'FLAG_VALID' | 'FLAG_NOT_FOUND' | 'NOOP';

// this function controls the logic for various feature flags
// it accepts a flag, and returns a status
// esp if flags conflict with each other. Any conflicts will yield and error on build

const checkFeatureFlag = (flag: FeatureFlagList): statusTypes => {
  if (!gameConfig.Feature_Flags) {
    return 'NOOP';
  }
  // "normalize" the flag list, as it can sometimes be an array or a string depending on how many items it has
  const featFlagList = [
    ...(Array.isArray(gameConfig.Feature_Flags)
      ? gameConfig.Feature_Flags
      : [gameConfig.Feature_Flags]),
  ];

  // handle any logical errors here and throw if there are any
  // this is done using the power of flags and logic 🤓
  flagLogicCheck(featFlagList);

  if (featFlagList.includes(flag)) {
    return 'FLAG_VALID';
  }
  return 'FLAG_NOT_FOUND';
};

export default checkFeatureFlag;
