import React from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRadio } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { RadioContext } from './RadioGroup';
import { Box } from '../Primitives';

const Radio = props => {
  const { children } = props;
  const state = React.useContext(RadioContext);
  const ref = React.useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.selectedValue === props.value;
  const strokeWidth = isSelected ? 6 : 2;

  return (
    <label
      style={{
        display: 'flex',
        placeContent: 'space-between',
        flexDirection: 'row-reverse',
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={24} height={24} aria-hidden="true" style={{ marginRight: 4 }}>
        <Box
          as="circle"
          cx={12}
          cy={12}
          r={8 - strokeWidth / 2}
          fill="none"
          sx={{
            stroke: 'foreground',
          }}
          strokeWidth={strokeWidth}
        />
        {isFocusVisible && (
          <circle
            cx={12}
            cy={12}
            r={11}
            fill="none"
            stroke="black"
            strokeWidth={2}
          />
        )}
      </svg>
      {children}
    </label>
  );
};

export default Radio;
