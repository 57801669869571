import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';

interface ParaProps {
  variant?: 'p1' | 'p2' | 'p3' | 'claimText' | 'claimBody';
  isBold?: boolean;
  as?: string;
}

const Para: StyledComponent<'p', any, ParaProps & BaseTextProps, never> =
  styled(BaseText)(
    variant({
      variants: {
        p1: {
          fontSize: [5, 6, 7],
        },
        p2: {
          fontSize: [4, 5, 6],
        },
        p3: {
          fontSize: [4, 4, 5],
        },
        claimText: {
          fontSize: [4, 6, 8],
        },
        claimBody: {
          fontSize: [5, 6, 7],
        },
        // very specific to fit large paragraphs inside of small screens (breaks out of theme)
        fitToSmallScreen: {
          fontSize: [4, 5, 6],
        },
      },
    }),
    ({ fontWeight = 0, color, fontSize, textDecoration = 'none' }) =>
      css({
        letterSpacing: '.18px',
        fontFamily: 'body',
        lineHeight: '140%',
        color: color || 'foreground',
        fontWeight,
        fontStyle: 'normal',
        fontSize,
        textDecoration,
      }),
  );

Para.defaultProps = {
  isBold: false,
  variant: 'p2',
  as: 'p',
};

export default Para;
