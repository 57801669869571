import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';

const headingVariant = ({ fontSize }) => {
  let variantConfig: any = {
    h1: {
      fontSize: [5, 6, 7],
    },
    h2: {
      fontSize: [4, 5, 6],
    },
    h3: {
      fontSize: [3, 4, 5],
    },
  };

  if (fontSize) {
    variantConfig = Object.keys(variantConfig).reduce((acc, currKey) => {
      acc[currKey] = {
        ...variantConfig[currKey],
        fontSize,
      };
      return acc;
    }, {});
  }
  return variant({
    prop: 'variant',
    variants: variantConfig,
  });
};

interface HeadingProps {
  variant?: 'h1' | 'h2' | 'h3';
  isBold?: boolean;
  as?: string;
  /** if you want to override the variants, mostly used for edge cases */
  fontSize?: number | string;
}

const Heading: StyledComponent<'h2', any, HeadingProps & BaseTextProps, never> =
  styled(BaseText).attrs(
    ({ variant: hybridVariant = 'h2', as: asOverride }) => ({
      as: asOverride ?? hybridVariant,
    }),
  )(
    ({ isBold = true, color }) =>
      css({
        letterSpacing: '.18px',
        fontFamily: 'body',
        lineHeight: '130%',
        color: color || 'foreground',
        fontWeight: isBold ? '700' : '400',
        fontStyle: 'normal',
      }),
    headingVariant,
  );

export default Heading;
