import { LabelHTMLAttributes } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css, { SystemStyleObject } from '@styled-system/css';
import sx from '../utils/sx';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';
import { ButtonContentStyled } from '../Buttons';

// TODO: It seems like SystemStyleObject can be extended here; this would allow all CSS-like props.
// A better solution might be to type SX as SystemStyleObject and not allow SystemStyleObjects in the main LabelProps.
interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  variant?: 'l1' | 'l2' | 'l3' | 'l4' | 'readMore';
  as?: string;
}

// TODO: There are still type errors in Header, ResultsView.

interface FlexProps {
  textDecoration?: string;
  flexDirection?: SystemStyleObject;
  alignItems?: string;
  alignSelf?: string;
}

const Label: StyledComponent<
  'p',
  any,
  LabelProps & BaseTextProps & FlexProps,
  never
> = styled(BaseText)(
  ({ color, textDecoration, flexDirection, alignItems, alignSelf }) =>
    css({
      textDecoration: textDecoration || 'inherit',
      flexDirection: flexDirection || 'column',
      justifyContent: 'center',
      alignItems: alignItems || 'center',
      alignSelf: alignSelf || 'initial',
      display: 'flex',
      lineHeight: '100%',
      fontFamily: 'body',
      fontStyle: 'normal',
      fontWeight: '500',
      color: color || 'text',
      // if inside a button or anchor, inherit buttons color settings
      [`button,a,${ButtonContentStyled} &`]: {
        color: 'inherit',
      },
    }),
  variant({
    variants: {
      l1: {
        fontSize: [4, 5, 6],
      },
      l2: {
        fontSize: [3, 4, 4],
        fontWeight: '700',
      },
      l3: {
        fontSize: [3, 4, 4],
      },
      l4: {
        fontSize: [2, 3, 3],
      },
      readMore: {
        fontSize: [4, 4, 4],
      }
    },
  }),
  sx,
);

Label.defaultProps = {
  variant: 'l2',
  as: 'label',
};

export default Label;
