import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { Module } from 'types';

export type ProfileState = {
    currentModule: Module | null;
    ids: string[];
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        currentModule: null,
        ids: [],
    } as ProfileState,
    reducers: {
        setProfile: (state, action: PayloadAction<{ profile: string[] }>) => {
            const { profile } = action.payload;
            state.ids = profile;
        },
        setCurrentModule: (state, action: PayloadAction<{ module: Module }>) => {
            const { module } = action.payload;
            state.currentModule = module;
        },
        resetProfile: state => {
            state.ids = [];
            state.currentModule = null;
        },
    },
});

export const selectProfile = (state: RootState) => state.profile.ids;
export const selectCurrentModule = (state: RootState) => state.profile.currentModule;

export const { setProfile, setCurrentModule, resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
