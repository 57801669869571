import React from 'react';
import { useAppDispatch } from '@hooks/redux-hooks';
import { logItem } from '@slices/loggingSlice';
import { Button } from 'workspace-core-ui';
import useTranslation from '@hooks/useTranslation';
import copyTextToClipboard from '@utils/copyTextToClipboard';
import { useAppSelector } from '@hooks/redux-hooks';

// Grab the session id so it can be used as content/rcp tracking
const SessionId = ({ ...delegated }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { sessionId } = useAppSelector(state => state.logging);


    return (
        <Button
            buttonSize="medium"
            variant="secondary"
            onPress={() => {
                dispatch(
                    logItem({
                        collection_name: 'events',
                        event_type: 'click',
                        target: 'copy_session_id_button',
                    }),
                );
                copyTextToClipboard({
                    text: sessionId,
                    copiedNotification: t('Copied'),
                    dispatch,
                    showVerboseNotification: true,
                });
            }}
            {...delegated}
        >
            {sessionId}
        </Button>
    );
};

export default SessionId;