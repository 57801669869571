import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LevelState = {
  /** keep track of what claim number you are on * */
  claimIndex: number;
  /** useful for the count displayed in the header of total q answered */
  claimsAnswered: number;
  /** this number might be different than points, so it gets its own value */
  claimsAnsweredCorrectly: number;
  /** our total claims */
  totalClaims: number;
  /** each time you level up, this value goes back down to zero (its a rolling count) */
  pointsForLevel: number;
  /** determined by airtable, but holds what is our next milestone (to make us progress) */
  pointsThreshold: number;
  /** once you meet a certain points threshold, we increment this value * */
  currentLevel: number;
};

type QuestionAnsweredProps = {
  userAnsweredCorrectly: boolean;
  givesPoints: boolean;
};

export const initialLevellingState: LevelState = {
  claimIndex: 1,
  claimsAnswered: 0,
  claimsAnsweredCorrectly: 0,
  totalClaims: 0,
  pointsForLevel: 0,
  pointsThreshold: 0,
  currentLevel: 0,
};

function nextThreshold(currentLevel: number, totalClaims: number): number {
  const level1 = Math.ceil(0.6 * totalClaims);
  const level2 = Math.ceil(0.8 * totalClaims) - level1;
  const level3 = totalClaims - level2 - level1;

  if (currentLevel === 0) {
    return level1;
  }
  if (currentLevel === 1) {
    return level2;
  }
  return level3;
}

export const levellingSlice = createSlice({
  name: 'levelling',
  initialState: initialLevellingState,
  extraReducers: {
    'gameState/resetGame': state => ({
      ...initialLevellingState,
      totalClaims: state.totalClaims,
      pointsThreshold: nextThreshold(0, state.totalClaims),
    }),
    'gameState/gameHasStarted': state => {
      if (state.currentLevel === state.pointsThreshold) {
        state.pointsThreshold = nextThreshold(0, state.totalClaims);
      }
    },
  },
  reducers: {
    setTotalClaims: (
      state,
      action: PayloadAction<{ newTotalClaims: number }>,
    ) => {
      state.totalClaims = action.payload.newTotalClaims;
      state.pointsThreshold = nextThreshold(0, state.totalClaims);
    },
    questionHasBeenAnswered: (
      state,
      action: PayloadAction<QuestionAnsweredProps>,
    ) => {
      state.claimsAnswered += 1;
      if (action.payload.userAnsweredCorrectly) {
        state.claimsAnsweredCorrectly += 1;
        if (action.payload.givesPoints) {
          state.pointsForLevel += 1;
        }
      }
    },
    userProgressedToNextClaim: (
      state,
      action: PayloadAction<{
        isRecall: boolean;
      }>,
    ) => {
      if (state.claimIndex < state.totalClaims && !action.payload.isRecall) {
        state.claimIndex += 1;
      }
      if (state.pointsForLevel === state.pointsThreshold) {
        state.pointsForLevel = 0;
        state.currentLevel += 1;
        state.pointsThreshold = nextThreshold(
          state.currentLevel,
          state.totalClaims,
        );
      }
    },
  },
});

export default levellingSlice.reducer;
