// these constants are shared between frontend and server
export const FIELDS = {
  claims: 'Claims',
  interrupts: 'Interrupts',
  carousels: 'Carousels',
  demographics: 'Demographics',
};

export const DYNAMIC_SCREENS_PLACEHOLDER = 'C_DYNAMIC_SCREENS';

// this maps the type of the item to its nested key
// in our airtable, if we have a type column entry, this maps that name to the appropriate linked column
export enum TYPE_OF_SCREEN_ENUM {
  Claim = 'Claim',
  Interrupt = 'Interrupt',
  Carousel = 'Carousel',
  Demographic = 'Demographic',
  ClaimPoolStub = 'ClaimPoolStub',
  DynamicClaim = 'DynamicClaim',
  Home = 'Home',
  End = 'End',
}

// these are screens that we count, shown as 1 of x on top of page
export const COUNTABLE_SCREENS = [
  TYPE_OF_SCREEN_ENUM.Claim,
  TYPE_OF_SCREEN_ENUM.ClaimPoolStub,
  TYPE_OF_SCREEN_ENUM.DynamicClaim,
];

export const STATUSES = {
  statusApproved: 'Approved For Game',
};

export const TABLE_NAMES = {
  defaultStreamsTableName: 'Screens',
  gameConfig: 'Game Configuration',
  profiler: 'Splitter',
};

// Animation related constants
export const FADE_IN_CONTAINER = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const FADE_IN_ITEM = {
  hidden: { opacity: 0, y: 15 },
  show: { opacity: 1, y: 0, transition: { ease: 'easeOut' } },
};
export const IS_IN_QA_MODE =
  typeof window !== 'undefined' &&
  (window.location.hash === '#dpstest' || process.env.GATSBY_QA === 'true');

export const WINDOW_HASH =
  typeof window !== 'undefined' ? window.location.hash : '';
