
export const defaultTimers = {
  timeOrigin: 0,
  connectEnd: 0,
  connectStart: 0,
  domComplete: 0,
  domContentLoadedEventEnd: 0,
  domContentLoadedEventStart: 0,
  domInteractive: 0,
  domainLookupEnd: 0,
  domainLookupStart: 0,
  fetchStart: 0,
  loadEventEnd: 0,
  loadEventStart: 0,
  redirectEnd: 0,
  redirectStart: 0,
  requestStart: 0,
  responseEnd: 0,
  responseStart: 0,
  secureConnectionStart: 0,
  unloadEventEnd: 0,
  unloadEventStart: 0,
};


