import { AnyAction } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import type { RootState } from '../redux/store';

/** Use throughout your app instead of plain `useDispatch` and `useSelector` */
export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
