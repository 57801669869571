// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const ClientOnly = ({ children }: Props): React.ReactNode => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return children;
};

export default ClientOnly;
