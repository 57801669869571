import React from 'react';
import { Modal, Flex, IconButton, Label, Divider } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import Toggle from 'workspace-core-ui/Toggle';
import {
  setIsBackgroundMusicOn,
  setAreSoundEffectsOn,
} from '@slices/gameStateSlice';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import { logItem } from '@slices/loggingSlice';
import { addBackgroundMusic, addGameSounds } from '@slices/audioSlice';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import PairShareModal from './PairShareModal-v2';

const OptionsModalButton = (): JSX.Element => {
  const { t } = useTranslation();
  const overlayState = useOverlayTriggerState({});
  const dispatch = useAppDispatch();
  // control states
  const { isBackgroundMusicOn, areSoundEffectsOn } = useAppSelector(
    s => s.gameState,
  );
  const shouldReduceMotion = useCustomReducedMotion();

  const disablePairShare = checkFeatureFlag('DISABLE_PAIR_SHARE') === 'FLAG_VALID';
  const disableSounds = checkFeatureFlag('DISABLE_ALL_SOUNDS') === 'FLAG_VALID';

  type Option =
    | { type: 'toggle', label: string, customDataCy: string, isSelected: boolean, disabled: boolean, onChange: (isSelected: boolean) => void }
    | { type: 'modal', label: string, modal: JSX.Element, disabled: boolean };

  const options: Option[] = [
    {
      type: 'toggle',
      label: t('Sound Modal Music Toggle'),
      customDataCy: 'musicToggle',
      isSelected: isBackgroundMusicOn,
      disabled: disableSounds,
      onChange: (isSelected: boolean) => {
        if (isSelected) {
          dispatch(addBackgroundMusic());
        }
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'toggle_settings',
            target: `music_${isSelected ? 'on' : 'off'}`,
          }),
        );
        dispatch(
          setIsBackgroundMusicOn({
            setTo: isSelected,
          }),
        );
      }
    },
    {
      type: 'toggle',
      label: t('Sound Modal SFX Toggle'),
      customDataCy: 'soundToggle',
      isSelected: areSoundEffectsOn,
      disabled: disableSounds,
      onChange: (isSelected: boolean) => {
        if (isSelected) {
          dispatch(addGameSounds());
        }
        dispatch(
          logItem({
            collection_name: 'events',
            event_type: 'toggle_settings',
            target: `sound_${isSelected ? 'on' : 'off'}`,
          }),
        );
        dispatch(setAreSoundEffectsOn({ setTo: isSelected }));
      }
    },
    {
      type: 'modal',
      label: 'Pair Share',
      disabled: disablePairShare,
      modal: <PairShareModal />,
    },
  ];

  const enabledOptions = options.filter((option) => (!option.disabled));

  if (enabledOptions.length === 0) {
    return <> </>;
  }

  return (
    <>
      <IconButton
        id="options-button"
        showOutline={false}
        onPress={() => overlayState.open()}
        iconName="music"
        aria-label="options"
        data-cy="optionsButton"
        aria-labelledby="options-button-label"
      />
      <Modal
        title={t('Options')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose
        closeLabel={t('Close')}
        modalBodyStyles={{
          bottom: 4,
          left: ['unset', '16px', '16px'],
        }}
      >
        <Flex mt={5} as="form" flexDirection="column">
          <> {enabledOptions.filter((option) => (!option.disabled)).map((option) => (
            option.type === 'toggle' ? (
              <>
                <Flex width="100%" alignItems="center" justifyContent="space-between">
                  <Label variant="l3">{option.label}</Label>
                  <Toggle
                    aria-label={option.label}
                    onLabel={t('On Label')}
                    offLabel={t('Off Label')}
                    reduceMotion={shouldReduceMotion}
                    customDataCy="musicToggle"
                    isSelected={option.isSelected}
                    onChange={option.onChange} />
                </Flex>
                <Divider my={1} />
              </>
            ) : (option.modal)
          ))}
          </>
        </Flex>
      </Modal>
      <Label
        sx={{
          cursor: 'pointer',
        }}
        for="options-button"
        id="options-button-label"
        variant="l3"
      >
        {t('Options')}
      </Label>
    </>
  );
};

export default OptionsModalButton;
