import React from 'react';
import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import { RadioGroupProps } from '@react-types/radio';

interface CustomRadioGroupProps extends RadioGroupProps {
  children: React.ReactNode;
}

// RadioGroup is the same as in the previous example
const RadioContext = React.createContext(null);

const RadioGroup = (props: CustomRadioGroupProps) => {
  const { children, label } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps}>
      <span {...labelProps}>{label}</span>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};

export { RadioGroup, RadioContext };
