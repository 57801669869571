import checkFeatureFlag from '@utils/checkFeatureFlag';
import {
  levellingSlice as levellingSliceV1,
  initialLevellingState as initialLevellingStateV1,
} from './levellingSlice-v1';
import {
  levellingSlice as levellingSliceV2,
  initialLevellingState as initialLevellingStateV2,
} from './levellingSlice-v2';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';

const levellingSlice = isModulesEnabled ? levellingSliceV2 : levellingSliceV1;
const initialLevellingState = isModulesEnabled
  ? initialLevellingStateV2
  : initialLevellingStateV1;

export { initialLevellingState };

export const { actions, reducer } = levellingSlice;
