import fisherYatesShuffle from './fisherYatesShuffle';
// TODO: write this in a more functional way, prevent mutations and also make it more readable
// one idea is to not hide implementation and steps inside of the yank function, but do things very declaratively so that we can see the evolution of the array

// ok I really need to think of a better name
// basically gives you back the modified array that includes puppet values (null) and also gives you way to reinsert what you removed along with the indices
const yankAndNullifyArrayItems = (
  arr: { [key: string]: unknown }[] | { [key: null]: unknown }[],
  whatToRemove:
    | {
        key: string;
        value: string;
      }
    | boolean,
  shouldShuffleYankedItems: boolean,
) => {
  const removedIndices = [] as number[];
  let removedItems = [] as { [key: string]: unknown }[];
  let tmpArr = [...arr];

  tmpArr = tmpArr.reduce((acc, currentValue, currentIndex) => {
    // remove any items that contain our matcher, or if our matcher is true, remove all non falsy
    if (
      currentValue &&
      (currentValue[whatToRemove?.key] === whatToRemove?.value ||
        whatToRemove === true)
    ) {
      removedIndices.push(currentIndex);
      removedItems.push(currentValue);
      acc.push(null);
    } else {
      acc.push(currentValue);
    }
    return acc;
  }, [] as any);

  if (shouldShuffleYankedItems) {
    removedItems = fisherYatesShuffle(removedItems);
  }

  const reinsertItems = (arrToInsertInto, preserveOriginalSticky = false) => {
    if (removedItems.length > 0) {
      if (preserveOriginalSticky) {
        // modify our removed items such that they have the same sticky property as the original order
        removedItems = removedItems.map((item, index) => {
          const itemCopy = { ...item };
          const originalItem = arr[removedIndices[index]];
          itemCopy.isSticky = originalItem.isSticky;
          return itemCopy;
        });
      }
      // reinsert the softpegs/dynamicPegs we took out (in the same order)
      removedIndices.forEach((indiceToInsert, index) => {
        arrToInsertInto.splice(indiceToInsert, 1, removedItems[index]);
      });
    }
  };

  return {
    tmpArr,
    reinsertItems,
  };
};

interface fixedShuffleProps {
  array: { [key: string]: unknown }[] | { [key: null]: unknown }[];
  /** A dynamicPeg is a key/value pair that acts very much like a regular peg, but it is ALWAYS shuffled amongst its peers (other items that contain its key value pairs). */
  dynamicPeg?: {
    key: string;
    value: string | boolean;
  } | null;
  /** A special key inside one of our objects in the array that marks the item as "unmovable", normally all shuffling is ignored if this key is found to be TRUE */
  peg: {
    key: string;
    value: string | boolean;
  } | null;
  /** A softpeg is a key/value pair that we want to remain in place, but be shuffled with its peers (other objects that contain the softpeg). It cannot contain an item with the pegged key that we see above, as that peg overrides any of this logic */
  softPeg?: {
    key: string;
    value: string | boolean;
  } | null;
  /** of the found softpegs, the algorithm will try to mutually shuffle them if this is true */
  shouldShuffleSoftPegs?: boolean;
  /** similarly, it will try to shuffle dynamic pegs here if true */
  shouldShuffleDynamicPegs?: boolean;
  /** of the rest of the stuff, anything that is not a peg or softpeg, the algorithm will try to mutually shuffle them as well */
  shouldShuffleRemaining?: boolean;
}

/** shuffle the array, except for the peg (the specified object key that we do not want to shuffle if it is true) or the softPeg (logic inside) */
const fixedShuffle = ({
  array = [],
  shouldShuffleSoftPegs = true,
  shouldShuffleRemaining = true,
  shouldShuffleDynamicPegs = true,
  peg = null,
  softPeg = null,
  dynamicPeg = null,
}: fixedShuffleProps): object[] => {
  // copy array so we do not accidentally mutate what is being passed in
  let modArray = [...array];
  // for a fixedShuffle, a peg is always required, otherwise its just a regular shuffle
  if (peg.length === 0) {
    throw new Error('Please specify a peg');
  }

  if (dynamicPeg) {
    const {
      tmpArr: tmpDynamicPeggedArr,
      reinsertItems: reinsertDynamicPeggedItems,
    } = yankAndNullifyArrayItems(
      modArray,
      dynamicPeg,
      shouldShuffleDynamicPegs,
    );
    modArray = tmpDynamicPeggedArr;
    // reinsert the dynamic pegs immediately after shuffling, however, preserve the original sticky property as ordinal
    reinsertDynamicPeggedItems(modArray, true);
  }

  // try to do our regularly pegged items
  const { tmpArr: tmpPeggedArr, reinsertItems: reinsertPeggedItems } =
    yankAndNullifyArrayItems(modArray, peg, false);
  modArray = tmpPeggedArr;

  const { tmpArr: tmpSoftPeggedArr, reinsertItems: reinsertSoftPeggedItems } =
    yankAndNullifyArrayItems(modArray, softPeg, shouldShuffleSoftPegs);
  modArray = tmpSoftPeggedArr;

  // run the yates shuffle on array, occluding our pegged and softPegged items
  const { tmpArr: tmpRestArr, reinsertItems: reinsertRestOfItems } =
    yankAndNullifyArrayItems(modArray, true, shouldShuffleRemaining);
  modArray = tmpRestArr;

  reinsertPeggedItems(modArray);
  reinsertSoftPeggedItems(modArray);
  reinsertRestOfItems(modArray);
  return modArray;
};

export default fixedShuffle;
