import React from 'react';
import { useCheckboxGroup } from '@react-aria/checkbox';
import {
  useCheckboxGroupState,
  CheckboxGroupState,
} from '@react-stately/checkbox';
import { VisuallyHidden } from '@react-aria/visually-hidden';

const CheckboxGroupContext =
  React.createContext<null | CheckboxGroupState>(null);

const CheckboxGroup = props => {
  const { children, label, className } = props;
  const state = useCheckboxGroupState(props);
  const { groupProps, labelProps } = useCheckboxGroup(props, state);

  return (
    <div {...groupProps} className={className}>
      <VisuallyHidden>
        <span {...labelProps}>{label}</span>
      </VisuallyHidden>
      <CheckboxGroupContext.Provider value={state}>
        {children}
      </CheckboxGroupContext.Provider>
    </div>
  );
};

export { CheckboxGroup, CheckboxGroupContext };
