import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';
import { Button } from '../Buttons';

interface DisplayProps {
  variant?: 'd1' | 'd2' | 'd3' | 'gameTitle' | 'claimTitle' | 'claimRecallTitle' | 'claimBody' | 'claimRecallBody';
  as?: string;
}

// breakpoints '43em', '62em', '82em'
const Display: StyledComponent<
  'div',
  any,
  DisplayProps & BaseTextProps,
  never
> = styled(BaseText)(
  ({ sx, fontWeight }) =>
    css({
      lineHeight: '100%',
      fontFamily: 'fun',
      color: 'foreground',
      fontStyle: 'normal',
      fontWeight: fontWeight ?? '400',
      letterSpacing: '.18px',
      ...sx,
    }),
  variant({
    variants: {
      d1: {
        fontSize: [15, 16, 17], // 5rem, 5.5rem, 6rem
      },
      d2: {
        fontSize: [11, 12, 13], // 3rem, 3.5rem, 4rem
      },
      d3: {
        fontSize: [6, 8, 10], // 1.5rem, 2rem, 2.5rem
      },
      claimTitle: {
        fontSize: [4, 5, 6], // 1.25rem, 1.25rem, 1.5rem
        padding: '16px  10px',
        fontWeight: 700,
        lineHeight: '1.3',
      },
      claimRecallTitle: {
        fontSize: [6, 7, 8], // 1.25rem, 1.25rem, 1.5rem
        padding: '16px',
        fontWeight: 700,
        lineHeight: '1.3',
      },
      claimBody: {
        fontSize: [5, 8, 9], // 2rem, 2.5rem, 3rem
        lineHeight: '1.5',
        paddingInline: '16px 10px',
        marginInline: 'auto'
      },
      claimRecallBody: {
        fontSize: [5, 8, 9], // 2rem, 2.5rem, 3rem
        lineHeight: '1.5',
        paddingInline: '16px',
        marginInline: 'auto'
      },
      gameTitle: {
        fontSize: [8, 14, 17], // 2rem, 4.5rem, 6rem
      },
    },
  }),
);

Display.defaultProps = {
  variant: 'd2',
  as: 'label',
};

export default Display;
