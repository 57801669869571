import React from 'react';
import Anchor from './Anchor';

const MailTo = ({ children }: { children: string }) => {
  // we expect the child to be a formatted email address
  // first, clean up the child string and remove quirky leading slashes that get added in (TODO: not sure why that occurs)
  const cleanChildren = children
    .trim()
    .normalize()
    .replace(String.fromCharCode(92), '');
  return (
    <Anchor target="_blank" href={`mailto:${cleanChildren}`}>
      {cleanChildren}
    </Anchor>
  );
};

export default MailTo;
