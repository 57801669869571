import React from 'react';
import { connect } from 'react-redux';
import { logItem } from '@slices/loggingSlice';
import { addNewNotification } from '@slices/gameStateSlice';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    // You can also log the error to an error reporting service
    this.props.dispatch(
      logItem({
        collection_name: 'events',
        event_type: 'error',
        location: window?.location?.pathname,
        target: `${error} ${JSON.stringify(errorInfo)}`,
      }),
    );
    this.props.dispatch(addNewNotification(error));
  }

  render() {
    if (this.state.hasError) {
      // TODO: fallback UI
      return <p>Something went wrong...please refresh and try again</p>;
    }

    return this.props.children;
  }
}

export default connect()(ErrorBoundary);
