import checkFeatureFlag from '@utils/checkFeatureFlag';
import {
  loggingSlice as loggingSliceV1,
  initialLoggingState as initialLoggingStateV1,
  batchLogItem as batchLogItemV1,
  logItem as logItemV1,
  startNewSession as startNewSessionV1,
  startNewPairShareSession as startNewPairShareSessionV1,
} from './loggingSlice-v1';
import {
  loggingSlice as loggingSliceV2,
  initialLoggingState as initialLoggingStateV2,
  batchLogItem as batchLogItemV2,
  logItem as logItemV2,
  startNewSession as startNewSessionV2,
  startNewPairShareSession as startNewPairShareSessionV2,
} from './loggingSlice-v2';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';

const loggingSlice = isModulesEnabled ? loggingSliceV2 : loggingSliceV1;
const initialLoggingState = isModulesEnabled
  ? initialLoggingStateV2
  : initialLoggingStateV1;
const batchLogItem = isModulesEnabled
  ? batchLogItemV2
  : batchLogItemV1;
const logItem = isModulesEnabled
  ? logItemV2
  : logItemV1;
const startNewSession = isModulesEnabled
  ? startNewSessionV2
  : startNewSessionV1;
const startNewPairShareSession = isModulesEnabled
  ? startNewPairShareSessionV2
  : startNewPairShareSessionV1;

export { initialLoggingState, batchLogItem, logItem, startNewSession, startNewPairShareSession };

export const { actions, reducer } = loggingSlice;
