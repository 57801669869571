/**
 * this is a wrapper around styled-system's css
 * https://github.com/primer/components/blob/master/src/sx.js
 */

import css, {
  SystemStyleObject,
  CssFunctionReturnType,
} from '@styled-system/css';

export interface SxProps {
  sx: SystemStyleObject;
}

export default ({ sx }): CssFunctionReturnType => css(sx);
