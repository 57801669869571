import styled, { StyledComponent } from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import Box, { BoxProps } from './Box';

type FlexProps = FlexboxProps | BoxProps;

const Flex: StyledComponent<'div', any, FlexProps, never> = styled(Box)(
  {
    display: 'flex',
  },
  flexbox,
);

export default Flex;
