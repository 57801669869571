import React from 'react';
import { useAppSelector } from '@hooks/redux-hooks';
import ClientOnly from '@components/ClientOnly';

type MapType =
  | Array<any>
  | {
      [key: string]: Array<any>;
    };

const ScoreMap = ({ map }: { map: MapType }) => {
  if (!map) {
    throw new Error('ScoreMap requires a map');
  }

  const { claimsAnsweredCorrectly, totalClaims } = useAppSelector(
    s => s.levelling,
  );

  const { currentStream } = useAppSelector(state => state.route);

  // probably the most common case, handle when just an array of items is passed
  if (Array.isArray(map)) {
    if (map.length !== totalClaims + 1) {
      throw new Error(
        `A ScoreMap map array was passed in, but it does not match the total number of claims + 1, expected ${
          totalClaims + 1
        } but got ${map.length}`,
      );
    }
    return <span>{map[claimsAnsweredCorrectly]}</span>;
  }

  // if an object is passed, it must contain keys the align with our current stream, and then we use that
  if (typeof map === 'object') {
    if (!currentStream) {
      throw new Error(
        `Cannot use object map ${JSON.stringify(map)} without multiple streams`,
      );
    }
    if (!map?.[currentStream]) {
      throw new Error(
        `${currentStream} does not exist in map object: ${JSON.stringify(map)}`,
      );
    }

    if (map[currentStream].length !== totalClaims + 1) {
      throw new Error(
        `A ScoreMap map object was passed in, but the entry associated with ${currentStream} does not match the total number of claims + 1 in that stream, expected ${
          totalClaims + 1
        } but got ${map[currentStream].length}`,
      );
    }
    return <span>{map[currentStream][claimsAnsweredCorrectly]}</span>;
  }
  return null;
};

const ScoreMapClientOnly = props => (
  <ClientOnly>
    <ScoreMap {...props} />
  </ClientOnly>
);

export default ScoreMapClientOnly;
