import { useReducedMotion } from 'framer-motion';
import gameConfig from '@content/gameconfig';

const useCustomReducedMotion = (): boolean => {
  const shouldReduceMotion = useReducedMotion();

  return !!shouldReduceMotion || gameConfig.Reduce_Motion;
};

export default useCustomReducedMotion;
