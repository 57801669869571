import React, { FC } from 'react';
import { BoxProps } from 'Primitives/Box';
import Button, { ButtonProps } from './Button';
import Icon, { IconProps } from '../Primitives/Icon';

export interface IconButtonProps extends IconProps {
  showOutline?: boolean;
  styledImageProps?: BoxProps;
}

const IconButton: FC<IconButtonProps & ButtonProps> = props => {
  const { iconName, iconSrc, styledImageProps, ...delegated } = props;
  return (
    <Button
      {...delegated}
      variant={props.variant || 'icon'}
      buttonSize={props.buttonSize || 'unset'}
    >
      <Icon iconName={iconName} iconSrc={iconSrc} {...styledImageProps} />
    </Button>
  );
};

export default IconButton;
