import React, { FC, HTMLAttributes, useContext } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { useCheckboxGroupItem } from '@react-aria/checkbox';
import {
  motion,
  useTransform,
  useMotionValue,
  MotionProps,
} from 'framer-motion';
import styled from 'styled-components';
import { AriaCheckboxGroupItemProps } from '@react-types/checkbox';
import { CheckboxGroupState } from '@react-stately/checkbox';
import { CheckboxGroupContext } from './index';

import { Button } from '../Buttons';

const tickVariants = {
  pressed: (isChecked: boolean) => ({ pathLength: isChecked ? 0.85 : 0.2 }),
  checked: { pathLength: 1 },
  unchecked: { pathLength: 0 },
};

const circleVariants = {
  checked: {
    stroke: '#000000',
    strokeWidth: 20,
    // TODO: theme this
    fill: '#88E9FF',
    pathLength: 1,
    transition: {
      duration: 0.1,
    },
  },
  unchecked: { stroke: '#ddd', strokeWidth: 10, pathLength: 0 },
};

const Super = styled.div({
  position: 'absolute',
  width: '16px',
  height: '16px',
  top: -4,
  right: -8,
  svg: {
    transform: 'scale(2)',
  },
});

interface AnimatedTickProps extends MotionProps {
  isChecked: boolean;
  fill?: string;
  stroke?: string;
}

export const AnimatedTick: React.FC<AnimatedTickProps> = ({
  isChecked,
  fill = 'rgba(0, 0, 0, 0)',
  stroke = 'black',
  ...delegated
}) => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  return (
    <motion.svg
      animate={isChecked ? 'checked' : 'unchecked'}
      viewBox="0 0 500 500"
      width="12"
      y="12"
      {...delegated}
    >
      <motion.path
        d="
	      M 150, 200
        m -75, 0
        a 75,75 0 1,0 350,0
        a 75,75 0 1,0 -350,0"
        fill={fill}
        strokeWidth="50"
        variants={circleVariants}
      />
      <motion.path
        d="M 50 120 L 148.658 257.373 L 341.808 0"
        transform="translate(150 145) rotate(0 160.904 128.687) scale(0.5)"
        fill={fill}
        strokeWidth="65"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        style={{ pathLength, opacity }}
        custom={isChecked}
      />
    </motion.svg>
  );
};

// Exported here because it was throwing an error in the Checkbox Story
// - not able to export a private variable
export interface CheckboxButtonProps extends AriaCheckboxGroupItemProps {
  children: React.ReactNode;
  onPress: () => void;
}

const CheckboxButton: FC<CheckboxButtonProps> = props => {
  const state = useContext(CheckboxGroupContext);
  const ref = React.useRef(null);
  const { onPress } = props;
  const { inputProps } = useCheckboxGroupItem(
    props,
    state as CheckboxGroupState,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const isCheckboxSelected = state ? state.isSelected(props.value) : false;

  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <Button
        isFocusVisible={isFocusVisible}
        isSelected={isCheckboxSelected}
        onPress={() => {
          state?.toggleValue(props.value);
          onPress();
        }}
        variant="tertiary"
        buttonSize="medium"
        aria-hidden="true"
        role="presentation"
        tabIndex={-1}
      >
        {props.children}
        <Super>
          <AnimatedTick isChecked={isCheckboxSelected} />
        </Super>
      </Button>
    </label>
  );
};

export default CheckboxButton;
