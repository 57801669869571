import React from 'react';
import { logItem } from '@slices/loggingSlice';
import { useAppDispatch } from '@hooks/redux-hooks';

const Anchor = ({ children, ...delegated }) => {
  const dispatch = useAppDispatch();
  const handleClick = eventType => {
    // record anchor click
    dispatch(
      logItem({
        collection_name: 'events',
        event_type: eventType,
        target: delegated.href,
      }),
    );
  };
  return (
    <a
      onMouseDown={() => handleClick('click')}
      onContextMenu={() => handleClick('context_menu')}
      target="_blank"
      rel="noopener noreferrer"
      {...delegated}
    >
      {children}
    </a>
  );
};

export default Anchor;
