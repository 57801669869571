import React from 'react';
import {
  Modal,
  Flex,
  Button,
  Label,
  Divider,
  Radio,
  RadioGroup,
  Icon,
  Box,
} from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { setCurrentLanguage } from '@slices/gameStateSlice';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import useTranslation from '@hooks/useTranslation';
import styled from 'styled-components';
import gameConfig from '@content/gameconfig';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';

interface LanguageModalButtonProps {
  buttonProps?: any;
}

const LanguageButton = styled(Button)({
  backgroundColor: 'transparent',
  padding: 0,
});

const LanguageModalButton = (props: LanguageModalButtonProps) => {
  const { t, g } = useTranslation();
  const { buttonProps } = props;
  const overlayState = useOverlayTriggerState({});
  const dispatch = useAppDispatch();
  // control states
  const { currentLanguage } = useAppSelector(s => s.gameState);
  // TODO: fix this casting later
  const langsUsed =
    gameConfig.Languages_Used.length > 0
      ? gameConfig.Languages_Used
      : [gameConfig.Languages_Used];

  return (
    <>
      <LanguageButton
        {...buttonProps}
        showOutline={false}
        onPress={() => overlayState.open()}
        variant="secondary"
        buttonSize="auto"
      >
        <Flex>
          <Label sx={{ cursor: 'pointer' }} variant="l3">
            {langsUsed &&
              g(langsUsed.find(e => e.Content_Type === currentLanguage))}
          </Label>
          <Icon iconName="chevron-up" />
        </Flex>
      </LanguageButton>
      <Modal
        reduceMotion={useCustomReducedMotion()}
        title={t('Language Modal Header')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        isDismissable
        showClose={false}
        closeLabel={t('Close')}
        modalBodyStyles={{
          bottom: ['32px', '48px', '48px'],
          right: ['unset', '16px', '16px'],
        }}
      >
        <Flex mt={5} as="form" flexDirection="column">
          <RadioGroup
            aria-label={t('Language Modal Header')}
            defaultValue={currentLanguage || 'en'}
            onChange={e => {
              dispatch(setCurrentLanguage({ setTo: e }));
            }}
          >
            {langsUsed.map((lang, index, orig) => (
              <Box key={lang.Content_Type}>
                <Radio value={lang.Content_Type}>
                  <Label sx={{ pointerEvents: 'none' }} variant="l3">
                    {lang && g(lang)}
                  </Label>
                </Radio>
                {index < orig.length - 1 && <Divider my={1} />}
              </Box>
            ))}
          </RadioGroup>
        </Flex>
      </Modal>
    </>
  );
};

export default LanguageModalButton;
