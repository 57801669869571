import React from 'react';
import { Button, Flex, Label, Modal } from 'workspace-core-ui';
import Toggle from 'workspace-core-ui/Toggle';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import useTranslation from '@hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import { setIsPairShareEnabled } from '@slices/gameStateSlice';
import { navigate } from 'gatsby';
import { WINDOW_HASH } from '@sharedConstants';
import styled from 'styled-components';
import css from '@styled-system/css';
import { logItem } from '@slices/loggingSlice';
import CustomMdxRenderer from './CustomMdxRenderer';
import { resetProfile } from '../module/profile-slice';

const ModalButton = styled(Button)(() =>
  css({
    paddingLeft: '10px',
  }),
);

const PairShareModal = (): JSX.Element => {
  const shouldReduceMotion = useCustomReducedMotion();
  const { t } = useTranslation();
  const [isEnableWarningModalVisible, setIsEnableWarningModalVisible] =
    React.useState(false);
  const [isDisableWarningModalVisible, setIsDisableWarningModalVisible] =
    React.useState(false);
  const { isPairShareEnabled, hasStarted } = useAppSelector(s => s.gameState);
  const dispatch = useAppDispatch();

  return (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      <Modal
        title={t('Enable pair share modal header')}
        isOpen={isEnableWarningModalVisible}
        isDismissable
        showClose={false}
        onClose={() => {
          setIsEnableWarningModalVisible(false);
        }}
      >
        <Flex
          width="100%"
          maxWidth="600px"
          alignItems="left"
          flexDirection="column"
        >
          <CustomMdxRenderer>
            {t('Enable pair share modal body', true)}
          </CustomMdxRenderer>
          <Flex width="100%" alignItems="right" justifyContent="end">
            <ModalButton
              variant="secondary"
              buttonSize="medium"
              isFullWidth={false}
              onPress={() => {
                setIsEnableWarningModalVisible(false);
              }}
            >
              {t('Cancel')}
            </ModalButton>
            <ModalButton
              variant="secondary"
              buttonSize="medium"
              isFullWidth={false}
              onPress={() => {
                setIsEnableWarningModalVisible(false);
                dispatch(setIsPairShareEnabled({ setTo: true }));
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'toggle_settings',
                    target: 'pair_share_on',
                  }),
                );
                dispatch(resetProfile())
                navigate(`/${WINDOW_HASH}`, {
                  state: { cameFromEnd: false },
                });
              }}
            >
              {t('OK')}
            </ModalButton>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        title={t('Disable pair share modal header')}
        isOpen={isDisableWarningModalVisible}
        isDismissable
        showClose={false}
        onClose={() => {
          setIsDisableWarningModalVisible(false);
        }}
      >
        <Flex
          width="100%"
          maxWidth="600px"
          alignItems="left"
          flexDirection="column"
        >
          <CustomMdxRenderer>
            {t('Disable pair share modal body', true)}
          </CustomMdxRenderer>
          <Flex width="100%" alignItems="right" justifyContent="end">
            <ModalButton
              variant="secondary"
              buttonSize="medium"
              isFullWidth={false}
              onPress={() => {
                setIsDisableWarningModalVisible(false);
              }}
            >
              {t('Cancel')}
            </ModalButton>
            <ModalButton
              variant="secondary"
              buttonSize="medium"
              isFullWidth={false}
              onPress={() => {
                setIsDisableWarningModalVisible(false);
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'toggle_settings',
                    target: 'pair_share_off',
                  }),
                );

                dispatch(setIsPairShareEnabled({ setTo: false }));
                navigate(`/${WINDOW_HASH}`, {
                  state: { cameFromEnd: false },
                });
              }}
            >
              {t('OK')}
            </ModalButton>
          </Flex>
        </Flex>
      </Modal>
      <Label variant="l3">{t('Pair share')}</Label>
      <Toggle
        aria-label={t('Pair share toggle')}
        onLabel={t('On Label')}
        offLabel={t('Off Label')}
        reduceMotion={shouldReduceMotion}
        customDataCy="pairShareToggle"
        isSelected={isPairShareEnabled}
        onChange={isSelected => {
          if (isSelected === true) {
            dispatch(
              logItem({
                collection_name: 'events',
                event_type: 'toggle_settings',
                target: 'pair_share_warning_on',
              }),
            );
            if (hasStarted === true) {
              setIsEnableWarningModalVisible(true);
            } else {
              dispatch(setIsPairShareEnabled({ setTo: true }));
            }
          } else if (hasStarted === true) {
            dispatch(
              logItem({
                collection_name: 'events',
                event_type: 'toggle_settings',
                target: 'pair_share_warning_off',
              }),
            );

            setIsDisableWarningModalVisible(true);
          } else {
            dispatch(setIsPairShareEnabled({ setTo: false }));
            navigate(`/${WINDOW_HASH}`, {
              state: { cameFromEnd: false },
            });
          }
        }}
      />
    </Flex>
  );
};

export default PairShareModal;
