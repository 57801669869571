import { Palette } from './theme.interface';

// prefer using HSLA colors, as they make it easier to lighten/brighten them
const palette: Palette = {
  greys: [
    'hsla(0, 0%, 0%, 1)',
    'hsla(0, 0%, 50%, 1)',
    'hsla(0, 0%, 80%, 1)',
    'hsla(0, 0%, 89%, 1)',
    'hsla(0, 0%, 95%, 1)',
    'hsla(0, 0%, 100%, 1)',
  ],
  yellows: ['hsla(43.2, 100%, 71.4%, 1)', 'hsla(35.9, 89.2%, 60%, 1)'],
  blues: [
    'hsla(191, 100%, 86%, 1)',
    'hsla(191, 100%, 77%, 1)',
    'hsla(191, 55%, 62%, 1)',
    'hsla(191, 100%, 96%, 1)',
  ],
  greens: [
    'hsla(166, 97%, 86%, 1)',
    'hsla(167, 54%, 64%, 1)',
    'hsla(155, 88%, 67%, 1)',
  ],
  white: '#FFFFFF',
};

export default palette;
