import checkFeatureFlag from '@utils/checkFeatureFlag';
import {
  routeSlice as routeSliceV1,
  initialRouteState as initialRouteStateV1,
} from './routeSlice-v1';
import {
  routeSlice as routeSliceV2,
  initialRouteState as initialRouteStateV2,
} from './routeSlice-v2';

const isModulesEnabled = checkFeatureFlag('MODULES') === 'FLAG_VALID';

const routeSlice = isModulesEnabled ? routeSliceV2 : routeSliceV1;
const initialRouteState = isModulesEnabled
  ? initialRouteStateV2
  : initialRouteStateV1;

export { initialRouteState };

export const { actions, reducer } = routeSlice;
