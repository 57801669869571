// this slice manages routing, namely what comes next after you answer a question
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COUNTABLE_SCREENS, TYPE_OF_SCREEN_ENUM } from '@sharedConstants';
import gameConfig from '@content/gameconfig';
import fixedShuffle from '@utils/fixedShuffle';
import fisherYatesShuffle from '@utils/fisherYatesShuffle';
import { Route, TypeOfScreen } from 'types';

export type RouteState = {
  currentStream: string | null;
  allOriginalRoutes: Route[];
  allRoutes: Route[];
  pooledRoutes: Route[];
  currentRoute: Route;
  nextRoute: Route;
  allRecallsInserted: boolean;
  allFollowupsInserted: boolean;
  followupsShown: number;
  recallsShown: number;
  insightRoutes: Route[];
  claimRoutes: Route[];
};

export const initialRouteState: RouteState = {
  currentStream: null as string | null,
  // TODO: maybe there is no need to even have this, since we have the orig .json file anyways
  allOriginalRoutes: [] as Route[],
  // this entire state is preloaded at runtime in store.ts
  allRoutes: [] as Route[],
  // optional when we have poolStubs show up in our main screen list of routes, those stubs get swapped with these pooled items
  pooledRoutes: [] as Route[],
  currentRoute: {
    name: 'home',
    url: '/',
    index: -1,
    typeOfScreen: TYPE_OF_SCREEN_ENUM.Home,
  } as Route,
  nextRoute: {} as Route,
  // this keeps track of if we inserted a recall route into our list (gets inserted when user answers question incorrectly)
  allRecallsInserted: gameConfig.Recall_Occurrence === 0,
  // this is so we know when to not dispatch this call from components, once this flag is true
  allFollowupsInserted: gameConfig.Followup_Occurrence === 0,
  // keep track of the followups user has seen, as the max is configured on airtable
  // remember, followups are shown immediately after a claim, repeated for x cycles
  followupsShown: 0,
  recallsShown: 0,
  insightRoutes: [] as Route[],
  claimRoutes: [] as Route[],
};

const maybeSkipRoutesOnInit = (routes: Route[], profile: string[]) => {
  if (profile.length === 0) {
    return [...routes];
  }

  const excludedScreens = ['Demographic', 'intro-disclaimer'];
  const newRoutes = routes.filter(route =>
    !excludedScreens.some(exclude =>
      route.typeOfScreen === TYPE_OF_SCREEN_ENUM[exclude] || route.name.includes(exclude)
    )
  );

  return newRoutes;
}

const urlMatcher = (findBy: 'url', url: string, routes: Route[]) => routes.findIndex(e => e[findBy] === url);
const tagMatcher = (tag: string[], routes: Route[]) => {
  const matchedRoutes = routes.reduce((acc: number[], route, index) => {
    if (route.tags?.some(t => tag.includes(t))) {
      acc.push(index);
    }
    return acc;
  }, []);

  if (matchedRoutes.length > 0) {
    const randomIndex = Math.floor(Math.random() * matchedRoutes.length);
    return matchedRoutes[randomIndex];
  }

  return -1;
};

const findMatchedRoute = (matchedIndex: number, nextIndex: number, claims: Route[], moduleRoutes: Route[]) => {
  const nextRoute = claims[matchedIndex];
  const updatedClaimRoutes = [...claims.slice(0, matchedIndex), ...claims.slice(matchedIndex + 1)];
  const updatedModuleRoutes = [
    ...moduleRoutes.slice(0, nextIndex),
    nextRoute,
    ...moduleRoutes.slice(nextIndex + 1)
  ];

  return { updatedClaimRoutes, updatedModuleRoutes, nextRoute };
};

const appendModuleToTags = (currentModule: string, tags: string[]): string[] => tags.map(tag => `${tag}~${currentModule}`);

const maybeMatchDynamicClaim = (currentModule: string, profileTags: string[], potentialNextRoute: Route, nextRouteIndex: number, claimRoutes: Route[], moduleRoutes: Route[]) => {
  if (potentialNextRoute.typeOfScreen === TYPE_OF_SCREEN_ENUM.DynamicClaim) {
    profileTags = appendModuleToTags(currentModule, profileTags);
    let matchedRouteIndex = tagMatcher(profileTags, claimRoutes);
    if (matchedRouteIndex !== -1) {
      return findMatchedRoute(matchedRouteIndex, nextRouteIndex, claimRoutes, moduleRoutes);
    }

    matchedRouteIndex = tagMatcher([currentModule], claimRoutes);
    if (matchedRouteIndex !== -1) {
      return findMatchedRoute(matchedRouteIndex, nextRouteIndex, claimRoutes, moduleRoutes);
    }

    return maybeMatchDynamicClaim(currentModule, profileTags, moduleRoutes[nextRouteIndex + 1], nextRouteIndex + 1, claimRoutes, moduleRoutes);
  }
  return { updatedClaimRoutes: claimRoutes, updatedModuleRoutes: moduleRoutes, nextRoute: potentialNextRoute };
};

export const routeSlice = createSlice({
  name: 'route',
  initialState: initialRouteState,
  extraReducers: {
    'gameState/resetGame': state =>
    // in case our game was reset (play again) we need to regenerate our original routes that we stored
    ({
      ...initialRouteState,
      allRoutes: state.allOriginalRoutes,
      allOriginalRoutes: state.allOriginalRoutes,
      pooledRoutes: state.pooledRoutes,
      claimRoutes: state.claimRoutes,
    }),
  },
  reducers: {
    setCurrentStream: (
      state,
      action: PayloadAction<{ streamName: string }>,
    ) => {
      const { streamName } = action.payload;
      state.currentStream = streamName;
    },
    /** initializes based on routes pregiven at provider, you can choose to override and just provide your own if need be */
    initializeRoutes: (
      state,
      action: PayloadAction<{
        routes?: Route[];
        currentStreamName?: string;
        profile: string[];
      }>,
    ) => {
      const allRoutes = action?.payload?.routes || [...state.allRoutes];
      let tmpAllRoutes = [...allRoutes];
      // if this is the case, we also have to see what routes are marked as "sticky" and if NOT randomize them
      // if we have a route with a typeOfScreen of "ClaimPoolStub", we need to swap it with items from routemaps pooled array
      const pooledRoutes = fisherYatesShuffle(
        [...state.pooledRoutes].filter(
          r => r.stream === action.payload.currentStreamName,
        ),
      );
      if (
        pooledRoutes.length > 0 &&
        tmpAllRoutes.some(
          r => r.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub,
        )
      ) {
        // swap out our ClaimPoolStub with the pooled items
        tmpAllRoutes = tmpAllRoutes.map(route => {
          if (route.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub) {
            const pooledRoute = pooledRoutes.shift() as Route;
            return {
              ...route,
              name: pooledRoute.name,
              url: pooledRoute.url,
              typeOfScreen: pooledRoute.typeOfScreen,
              isFromPool: pooledRoute.isFromPool,
            };
          }
          return route;
        });
      }

      tmpAllRoutes = fixedShuffle({
        array: tmpAllRoutes,
        peg: { key: 'isSticky', value: true },
        softPeg: {
          key: 'typeOfScreen',
          value: TYPE_OF_SCREEN_ENUM.Interrupt,
        },
        dynamicPeg: {
          key: 'isFromPool',
          value: true,
        },
        // TODO: if we turn off randomization or maybe have controls for this in future, then the shuffling need not be done here
        shouldShuffleSoftPegs: true,
        shouldShuffleDynamicPegs: true,
        shouldShuffleRemaining: gameConfig.Shuffle_Screens,
      }) as Route[];
      // set these shuffled routes as our state, but tack on an end game screen too
      state.allRoutes = [
        ...tmpAllRoutes,
        {
          name: 'end',
          url: '/end',
          index: (tmpAllRoutes?.[tmpAllRoutes.length - 1]?.index ?? 0) + 1,
          typeOfScreen: TYPE_OF_SCREEN_ENUM.End,
          isSticky: true,
        },
      ];
      state.allRoutes = maybeSkipRoutesOnInit(state.allRoutes, action.payload.profile);
    },
    setCurrentRoute: (
      state,
      action: PayloadAction<{
        findBy?: 'url' | 'name';
        compareAgainst: string;
      }>,
    ) => {
      const { findBy = 'url', compareAgainst } = action.payload;
      const currRouteIndex = state.allRoutes.findIndex(
        e => e[findBy] === compareAgainst,
      );
      state.currentRoute = state.allRoutes[currRouteIndex];
    },
    inferNextRoute: (
      state,
      action: PayloadAction<{
        findBy?: 'url';
        compareAgainst: string;
        profile: string[];
        module: string;
      }>,
    ) => {
      const { findBy = 'url', compareAgainst, profile, module } = action.payload;

      const maybeSkipDemographics = (currentRouteIndex: number, route: Route, hasProfile: boolean) => {
        if (route.typeOfScreen === TYPE_OF_SCREEN_ENUM.Demographic && hasProfile) {
          return currentRouteIndex + 2;
        }
        return currentRouteIndex + 1;
      }

      const currRouteIndex = urlMatcher(findBy, compareAgainst, state.allRoutes);
      state.currentRoute = state.allRoutes[currRouteIndex];
      let potentialNextRoute = state.allRoutes[currRouteIndex + 1];

      const nextRouteIndex = maybeSkipDemographics(currRouteIndex, potentialNextRoute, profile.length > 0);
      potentialNextRoute = state.allRoutes[nextRouteIndex];

      const { updatedClaimRoutes, updatedModuleRoutes, nextRoute } = maybeMatchDynamicClaim(module, profile, potentialNextRoute, nextRouteIndex, state.claimRoutes, state.allRoutes);
      state.nextRoute = nextRoute;
      state.allRoutes = updatedModuleRoutes;
      state.claimRoutes = updatedClaimRoutes;
    },
    insertFollowupScreen: (state, action: PayloadAction<{ url: string }>) => {
      const { url } = action.payload;
      const tmpRoutes = [...state.allRoutes];
      // followups always occur right after the current screen, no matter what

      // create as many followups to insert as configured
      const followupsToInsert =
        gameConfig.Followup_Control &&
        gameConfig.Followup_Control.map(
          (f, index) =>
          ({
            url: `${url}-followup-${++index}`,
            typeOfScreen: TYPE_OF_SCREEN_ENUM.Interrupt,
            name: f?.Name || '',
          } as {
            url: string;
            typeOfScreen: TypeOfScreen;
            name: string;
          }),
        );

      // grab the index of the passed in url payload
      const currentRouteIndex = state.allRoutes.findIndex(e => e.url === url);
      if (currentRouteIndex === -1) {
        throw new Error(
          'You passed in a url that does not exist in our route-map',
        );
      }
      if (followupsToInsert && followupsToInsert.length > 0) {
        let tmpFollowupsToInsert = gameConfig.Shuffle_Followup
          ? fisherYatesShuffle(followupsToInsert)
          : [...followupsToInsert];
        // also, insert only as many followups as defined in the window
        if (typeof gameConfig.Followup_Window === 'number') {
          // get only first n of the follows as defined in window
          tmpFollowupsToInsert = tmpFollowupsToInsert.slice(
            0,
            gameConfig.Followup_Window,
          );
        }
        tmpRoutes.splice(currentRouteIndex + 1, 0, ...tmpFollowupsToInsert);
      }
      state.allRoutes = tmpRoutes;
      state.followupsShown += 1;
      if (state.followupsShown === gameConfig.Followup_Occurrence) {
        state.allFollowupsInserted = true;
      }
    },

    // add a recall screen at an index defined by our game config
    insertRecallScreen: (
      state,
      action: PayloadAction<InsertRecallScreenProps>,
    ) => {
      // NOTE, for now the delta should only count typeOfScreen: "Claim"
      const { url, customDelta } = action.payload;
      // we had generated this static recall page for claims at build time
      const recallToInsert = {
        url: `${url}-recall`,
        name: 'recall',
        typeOfScreen: TYPE_OF_SCREEN_ENUM.Claim,
        isRecall: true,
      } as const;
      // grab the index of the passed in url payload
      const currentRouteIndex = state.allRoutes.findIndex(e => e.url === url);

      if (currentRouteIndex === -1) {
        throw new Error(
          'You passed in a url that does not exist in our route-map',
        );
      }
      // insert the recall for that specific url a few steps after (as defined in the game config)
      /* So the recall appears AT the turn you specified, if you say 3
      - 1
      - 2 --current
      - 3 -- 1 step
      - 4 -- 2 step
      - 5 -- 3 step - Recall will appear here, provided the last things were Claims
      */
      const tmpRoutes = [...state.allRoutes];
      const recallDelta = customDelta || gameConfig.Recall_Delta || 0;
      // the delta we get assumes that its only for CLAIMS, so we need an "actual" value to tabulate how many steps it will take to get something like "appear after 3 claims"
      let actualDelta = 0;
      let claimsIterated = 0;

      // let go through each route
      for (let i = 0; i < tmpRoutes.length; i++) {
        const currentRoute = tmpRoutes[i];
        // start from the start, and start our count once we hit the currentRoutes index
        if (i > currentRouteIndex) {
          // this will keep incrementing until we stop
          actualDelta += 1;
          // each time we see a claim route, increment our claim num, we do not count recalls
          // as that leads to a "bunching" problem where you get repeated recalls in a row
          if (
            COUNTABLE_SCREENS.includes(currentRoute.typeOfScreen) &&
            !currentRoute?.isRecall
          ) {
            claimsIterated += 1;
          }
          if (claimsIterated === recallDelta) break;
        }
      }

      const whereToInsert = currentRouteIndex + actualDelta;
      // we cannot insert the recall at or after the last index
      // we also want the claims iterated to equal the delta, otherwise we have undershot in our loop
      if (whereToInsert < tmpRoutes.length && claimsIterated === recallDelta) {
        tmpRoutes.splice(whereToInsert, 0, recallToInsert);
        state.allRoutes = tmpRoutes;
        state.recallsShown += 1;
        if (state.recallsShown === gameConfig.Recall_Occurrence) {
          state.allRecallsInserted = true;
        }
      }
    },
  },
});

export type InsertRecallScreenProps = {
  url: string;
  customDelta?: number;
};
