import React from 'react';
import { useAppSelector } from '@hooks/redux-hooks';

const Score = ({
  asPercent = false,
  interstice = '/',
  flip = false,
  ...delegated
}) => {
  const { claimsAnsweredCorrectly, totalClaims } = useAppSelector(
    s => s.levelling,
  );
  return (
    <span {...delegated}>
      {asPercent ? (
        Math.round((claimsAnsweredCorrectly / totalClaims) * 100) | 0
      ) : (
        <span>
          {flip
            ? `${totalClaims} ${interstice} ${claimsAnsweredCorrectly}`
            : `${claimsAnsweredCorrectly} ${interstice} ${totalClaims}`}
        </span>
      )}
    </span>
  );
};

export default Score;
