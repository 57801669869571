import styled from 'styled-components';
import { variant, space, layout, position, compose } from 'styled-system';
import css from '@styled-system/css';
import sx from '../utils/sx';

export default styled('button')(
  css({
    outline: 'none',
    placeContent: 'center',
    alignItems: 'center',
    appearance: 'none',
    textAlign: 'center',
    color: 'black',
    // TODO: mb need this? not sure
    padding: 0,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  }),
  compose(position, layout, space),
  variant,
  sx,
);
