// making string representation of the data purposefully obtuse, count by https://memory-alpha.fandom.com/wiki/Phonetic_alphabet
const U_ID = 'able';
const TTL_IN_MS = 2419200000; // 28 days > https://www.kylesconverter.com/time/milliseconds-to-days#2419200000

// check if json is valid
const isValidJson = (json: string) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

// to appease gatsby server side rendering
const isBrowser = typeof window !== `undefined`;

export const getLocalStoreUserId = () => {
  if (isBrowser) {
    const localStoreJson = localStorage.getItem(U_ID);
    if (!localStoreJson) {
      return null;
    }

    const localStoreObj = isValidJson(localStoreJson)
      ? JSON.parse(localStoreJson)
      : {};

    if (!localStoreObj.expires || Date.now() > localStoreObj?.expires) {
      console.warn('local store user id expired');
      localStorage.removeItem(U_ID);
      return null;
    }
    return localStoreObj.value;
  }
};

// store data but with an expiration date, it only expires when user visits site again
export const setLocalStoreUserId = (s: string) => {
  if (isBrowser) {
    localStorage?.setItem(
      U_ID,
      JSON.stringify({
        value: s,
        expires: Date.now() + TTL_IN_MS,
      }),
    );
  }
};
