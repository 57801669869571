const zip = (a: string[], b: string[]) => a.map((k, i) => [k, b[i]]);
/* Encrypt <message> with a simple mixed alphabet cipher
 */
const source_characters = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');
const target_characters = 'g1xcou5bnklir28djv3sqwyhfm409z6tap7e'.split('');

const encrypt = (message: string) => {
  const cipher = Object.fromEntries(zip(source_characters, target_characters));
  return message.replace(/[a-z0-9]/g, c => cipher[c]);
};

const decrypt = (message: string) => {
  const cipher = Object.fromEntries(zip(target_characters, source_characters));
  return message.replace(/[a-z0-9]/g, c => cipher[c]);
}

export {encrypt, decrypt};

